@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.investorLogin {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @include mqMaxWidth(1023px) {
    display: block;
    margin: 0 auto;
  }
}

.investorLoginContainer {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 50px;
  position: relative;

  @include mqMaxWidth(480px) {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  margin: 0;
  text-align: center;
  font-size: 24px;
  line-height: 33px;
}

.caption {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 22px;
  text-align: center;
  color: #616161;
}

.loginForm {
  min-height: 238px;
}

.loginFormEntryCode {
  position: relative;
}

.loginFormLabel {
  span {
    color: #c0c0c0;
  }
}

.loginFormLabelIcon {
  position: relative;
  top: 2px;
  margin-left: 4px;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;

    &:hover {
      fill: #2d2d2d;
    }
  }
}

.loginFormInput {
  min-height: 36px;
}

.terms {
  margin: 30px auto;
  width: 100%;
  max-width: 268px;
  text-align: center;
  color: #616161;

  br {
    display: none;
  }
}

.compatibilityLinkContainer {
  width: 100%;
  background: rgba(0, 0, 0, 0.04);
  font-size: 14px;
  text-align: center;
  padding: 25px 0;
  font-weight: 500;
}

.loginFormButton {
  margin-top: 22px;
}

.customMessage {
  padding: 15px;
  overflow: auto;
  word-break: break-word;
}

.customLoginForm,
.customLoginFormLabel {
  color: var(--disclaimerLabelTextColor);

  span {
    color: var(--disclaimerLabelTextColor);
  }
}

.customLoginFormLabelIcon {
  i {
    fill: var(--disclaimerLabelTextColor);
  }
}

.customInvestorLoginContainer {
  .title {
    color: var(--disclaimerPrimaryTextColor);
  }

  .caption {
    color: var(--disclaimerSecondaryTextColor);
  }

  .customMessage {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
