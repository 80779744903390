.btn {
  background-color: var(--disclaimerButtonColor);
  color: var(--disclaimerButtonTextColor);

  &:hover {
    background-color: var(--disclaimerButtonColor);
    color: var(--disclaimerButtonTextColor);
  }
}

.customCardContainer {
  background-color: var(--disclaimerTileColor) !important;
}

.customCompatibilityLinkContainer {
  background: rgba(0, 0, 0, 0.04);

  a {
    color: var(--disclaimerLinkColor);
  }
}

.terms {
  a {
    color: var(--disclaimerLinkColor);
  }
}

.customInvestorEmailVerificationContainer {
  h1 {
    color: var(--disclaimerPrimaryTextColor);
  }
  label {
    color: var(--disclaimerLabelTextColor);

  }
  .customSecondaryText {
    color: var(--disclaimerSecondaryTextColor);
  }
}
